import React from "react"
import Carreiras from "../../components/carreiras/carreiras.js"
import { Helmet } from "react-helmet"

const CarreirasPage = () => (
  <div>
    <Helmet htmlAttributes={{ lang: "pt-br" }}>
      <title>Carreiras @deli</title>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Faça parte do time responsável pelo sistema para restaurantes mais completo do mercado. Temos vagas para desenvolvedores remotos!"
      />
    </Helmet>
    <Carreiras />
  </div>
)

export default CarreirasPage
