import React from "react"
import styled from "styled-components"
import { Row, Col } from "antd"
import Footer from "../footer.js"
import { FaWhatsapp } from "react-icons/fa"

class Carreiras extends React.Component {
  state = {
    show1: false,
    show2: false,
    show3: false,
    show4: false,
  }
  render() {
    if (this.state.show1 === true) {
      return <div>Hello</div>
    }
    return (
      <div>
        <Header>
          <Row style={{ height: "100%" }}>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-start",
                paddingLeft: "24px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "Righteous",
                  fontSize: "1.8em",
                  width: "64px",
                  margin: "0px",
                }}
              >
                deli
              </p>
            </Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col
              xs={18}
              sm={18}
              md={18}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: "24px",
              }}
            >
              <FaWhatsapp size="1.78em" />
              <span
                style={{
                  fontFamily: "IBM Plex Sans",
                  color: "#3f6aea",
                  fontSize: "1.2em",
                  marginLeft: "6px",
                }}
              >
                {"(41) 9 9757-4479"}
              </span>
            </Col>
          </Row>
        </Header>
        <CareersContainer>
          <Center>
            <Valign>
              <Row
                type="flex"
                gutter={40}
                style={{ display: "inline-block", width: "90%" }}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={10}
                  xl={10}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      marginTop: "26px",
                      color: "white",
                      fontFamily: "IBM Plex Sans",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    CARREIRAS
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "216px",
                      height: "8px",
                      backgroundColor: "#3f6aea",
                    }}
                  ></div>

                  <h3
                    style={{
                      color: "white",
                      fontSize: "30px",
                      fontWeight: "lighter",
                      marginTop: "26px",
                      display: "inline-block",
                      width: "90%",
                      textAlign: "left",
                    }}
                  >
                    Juntos, criamos tecnologias que viabilizam empreendimentos
                    gastronômicos.
                  </h3>
                  <p
                    style={{
                      textAlign: "left",
                      fontFamily: "IBM Plex Sans",
                      color: "white",
                      fontSize: "17px",
                    }}
                  >
                    Formamos uma equipe implacável porque cultivamos
                    diversidade, transparência e responsabilidade. Estas
                    virtudes nos permitem entregar excelentes serviços e são a
                    base para a experimentação e o crescimento profissional.
                    Nesse contexto, estamos constantemente buscando integrantes
                    de diferentes históricos e disciplinas para dinamizar nossa
                    equipe, que cresce rapidamente.
                  </p>

                  <h3
                    style={{
                      color: "white",
                      fontWeight: "lighter",
                      marginBottom: "24px",
                    }}
                  >
                    Benefícios:
                  </h3>

                  <ul>
                    <li
                      style={{
                        fontFamily: "IBM Plex Sans",
                        color: "white",
                        textAlign: "left",
                      }}
                    >
                      Muita autonomia e tecnologia
                    </li>

                    <li
                      style={{
                        fontFamily: "IBM Plex Sans",
                        color: "white",
                        textAlign: "left",
                      }}
                    >
                      Período de férias anual remunerado
                    </li>

                    <li
                      style={{
                        fontFamily: "IBM Plex Sans",
                        color: "white",
                        textAlign: "left",
                      }}
                    >
                      Vale transporte e refeição
                    </li>
                    <li
                      style={{
                        fontFamily: "IBM Plex Sans",
                        color: "white",
                        textAlign: "left",
                      }}
                    >
                      Possibilidade de trabalhar remotamente
                    </li>
                  </ul>
                </Col>

                <CustomCol xs={24} sm={24} md={24} lg={14} xl={14}>
                  <a href="/carreiras/estagio">
                    <PositionCard>
                      <div
                        style={{
                          fontFamily: "IBM Plex Sans",
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "1.2em",
                        }}
                      >
                        Estágio em Desenvolvimento Web
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "8px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "IBM Plex Sans",
                            color: "#5b5b5b",
                          }}
                        >
                          30HRS SEMANAIS
                        </div>
                        <div
                          style={{
                            fontFamily: "IBM Plex Sans",
                            color: "#5b5b5b",
                          }}
                        >
                          CURITIBA/PR
                        </div>
                      </div>
                    </PositionCard>
                  </a>
                  <a href="/carreiras/frontend">
                    <PositionCard>
                      <div
                        style={{
                          fontFamily: "IBM Plex Sans",
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "1.2em",
                        }}
                      >
                        Desenvolvedor Front-End
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "8px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "IBM Plex Sans",
                            color: "#5b5b5b",
                          }}
                        >
                          FULL-TIME
                        </div>
                        <div
                          style={{
                            fontFamily: "IBM Plex Sans",
                            color: "#5b5b5b",
                          }}
                        >
                          CURITIBA/PR
                        </div>
                      </div>
                    </PositionCard>
                  </a>
                  <a href="/carreiras/backend">
                    <PositionCard>
                      <div
                        style={{
                          fontFamily: "IBM Plex Sans",
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "1.2em",
                        }}
                      >
                        Desenvolvedor Back-End
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "8px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "IBM Plex Sans",
                            color: "#5b5b5b",
                          }}
                        >
                          FULL-TIME
                        </div>
                        <div
                          style={{
                            fontFamily: "IBM Plex Sans",
                            color: "#5b5b5b",
                          }}
                        >
                          REMOTO
                        </div>
                      </div>
                    </PositionCard>
                  </a>
                  <a href="/carreiras/marketing">
                    <PositionCard>
                      <div
                        style={{
                          fontFamily: "IBM Plex Sans",
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "1.2em",
                        }}
                      >
                        Analista de Marketing
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "8px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "IBM Plex Sans",
                            color: "#5b5b5b",
                          }}
                        >
                          FULL-TIME
                        </div>
                        <div
                          style={{
                            fontFamily: "IBM Plex Sans",
                            color: "#5b5b5b",
                          }}
                        >
                          CURITIBA/PR
                        </div>
                      </div>
                    </PositionCard>
                  </a>
                </CustomCol>
              </Row>
            </Valign>
          </Center>
        </CareersContainer>
        <Footer />
      </div>
    )
  }
}

export default Carreiras

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  color: #3f6aea;
  height: 48px;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`
const Center = styled.div`
  display: table;
  width: 100%;
  height: auto;
`
const Valign = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
`

const CustomCol = styled(Col)`
  @media (min-width: 992px) {
    margin-top: 128px;
  }
`

const CareersContainer = styled.div`
  padding-top: 48px;
  width: 100%;
  min-height: 95vh;
  height: auto;
  background: rgb(135, 168, 197);
  background: linear-gradient(
    135deg,
    rgba(135, 168, 197, 1) 22%,
    rgba(114, 63, 234, 1) 100%
  );

  @media (min-width: 1200px) {
    background: rgb(135, 168, 197);
    background: linear-gradient(
      90deg,
      rgba(135, 168, 197, 1) 22%,
      rgba(114, 63, 234, 1) 100%
    );
  }
`

const PositionCard = styled.div`
  cursor: pointer;
  transition: 0.1s;
  border-radius: 6px;
  background-color: white;
  padding: 2.5%;
  height: 94px;
  width: 100%;
  margin-bottom: 10px;

  &:hover {
    margin-top: -3px;
    margin-bottom: 13px;
    box-shadow: 0 0 54px rgba(0, 0, 0, 0.2), 0 5px 20px rgba(0, 0, 0, 0.07),
      0 0 1px rgba(0, 0, 0, 0.12);
  }
`
